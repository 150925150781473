import { useMediaQuery } from '@vueuse/core'

export function useDevice() {
  const isSmallScreen = useMediaQuery('(max-width: 349px)')
  const isMobile = useMediaQuery('(max-width: 767px)')
  const isDesktop = useMediaQuery('(min-width: 768px)')
  const isMobileApp: boolean = import.meta.env.VITE_MOBILE_APP === 'true'

  function getScreenDPI() {
    const div = document.createElement('div')
    div.style.width = '1in' // 1 inch in CSS
    div.style.visibility = 'hidden'
    document.body.appendChild(div)

    // Get the DPI value by checking how many pixels correspond to 1 inch
    const dpiCalculated = div.offsetWidth
    document.body.removeChild(div)
    return dpiCalculated
  }

  return { isMobile, isSmallScreen, isDesktop, isMobileApp, getScreenDPI }
}
