import { LogLevel } from '@azure/msal-browser'
import {
  AuthProvider,
  Auth0Provider,
  MSALProvider,
  OAuth2Provider,
  TokenProvider,
  AuthProviderName,
} from './auth/types'
import router from './router'

const defaultProvider = (import.meta.env.VITE_AUTH_DEFAULT_PROVIDER || window.ENV.AUTH_DEFAULT_PROVIDER) as
  | AuthProviderName
  | undefined

const auth0Provider: Auth0Provider = {
  name: 'auth0',
  config: {
    domain: import.meta.env.VITE_AUTH0_DOMAIN || window.ENV.AUTH0_DOMAIN,
    client_id: import.meta.env.VITE_AUTH0_CLIENT_ID || window.ENV.AUTH0_CLIENT_ID,
    audience: import.meta.env.VITE_AUTH0_AUDIENCE || window.ENV.AUTH0_AUDIENCE,
    dbName: import.meta.env.VITE_AUTH0_DB_NAME || window.ENV.AUTH0_DB_NAME,
    redirect_uri:
      defaultProvider === 'auth0'
        ? window.location.origin + router.resolve({ name: 'dashboard' }).href
        : window.location.origin + router.resolve({ name: 'dashboard' }).href + '?provider=auth0',
    authorizeTimeoutInSeconds: 10,
  },
}

const msalProvider: MSALProvider = {
  name: 'msal',
  config: {
    auth: {
      clientId: import.meta.env.VITE_MSAL_CLIENT_ID || window.ENV.MSAL_CLIENT_ID,
      authority: import.meta.env.VITE_MSAL_AUTHORITY || window.ENV.MSAL_AUTHORITY, // See: https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-client-application-configuration
      redirectUri:
        defaultProvider === 'msal'
          ? window.location.origin + '/dashboard'
          : window.location.origin + '/dashboard' + '?provider=msal', // Avoid using the extra parameter when it's default auth method to avoid causing extra config in Azure
      postLogoutRedirectUri: window.location.origin,
      navigateToLoginRequestUrl: true, // This option automatically redirects to the origin URL after the user gets successfully authenticated, this is different than the redirectUri because it can be any protected page
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
          if (containsPii) {
            return
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message)
              return
            case LogLevel.Info:
              console.info(message)
              return
            case LogLevel.Verbose:
              console.debug(message)
              return
            case LogLevel.Warning:
              console.warn(message)
              return
          }
        },
        piiLoggingEnabled: false,
        logLevel: LogLevel.Verbose,
      },
    },
  },
}

const oauth2Provider: OAuth2Provider = {
  name: 'oauth2',
  config: {
    clientId: import.meta.env.VITE_OAUTH2_CLIENT_ID || window.ENV.OAUTH2_CLIENT_ID,
    scopes: import.meta.env.VITE_OAUTH2_SCOPES || window.ENV.OAUTH2_SCOPES,
    authorizationEndpoint:
      import.meta.env.VITE_OAUTH2_AUTHORIZATION_ENDPOINT || window.ENV.OAUTH2_AUTHORIZATION_ENDPOINT,
    tokenEndpoint: import.meta.env.VITE_OAUTH2_TOKEN_ENDPOINT || window.ENV.OAUTH2_TOKEN_ENDPOINT,
    userInfoEndpoint: import.meta.env.VITE_OAUTH2_USERINFO_ENDPOINT || window.ENV.OAUTH2_USERINFO_ENDPOINT,
    logoutEndpoint: import.meta.env.VITE_OAUTH2_LOGOUT_ENDPOINT || window.ENV.OAUTH2_LOGOUT_ENDPOINT,
    redirectUri:
      defaultProvider === 'oauth2'
        ? window.location.origin + router.resolve({ name: 'dashboard' }).href
        : window.location.origin + router.resolve({ name: 'dashboard' }).href + '?provider=oauth2', // Avoid using the extra parameter when it's default auth method to avoid causing extra config
  },
}

const tokenProvider: TokenProvider = {
  name: 'token',
  config: {
    tokenLocation: 'localStorage',
    logoutURL: '/',
  },
}

export const availableProviders = (import.meta.env.VITE_AUTH_PROVIDERS || window.ENV.AUTH_PROVIDERS)
  ?.split(',')
  .map((s) => s.trim())

export const defaultAuthProvider: AuthProvider = getConfigByProvider(defaultProvider)

export function getConfigByProvider(provider?: string) {
  if (provider === 'msal') {
    return msalProvider
  } else if (provider === 'oauth2') {
    return oauth2Provider
  } else if (provider === 'token') {
    return tokenProvider
  } else {
    return auth0Provider
  }
}
